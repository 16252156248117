import React from "react";
import Slider from "react-slick";

import essentique from "../images/portfolioimgs/essent.png";
import edge from "../images/portfolioimgs/edgedynasty.png";
import restaurant from "../images/portfolioimgs/restarurant.png";
import videospark from "../images/portfolioimgs/videospark.jpg";
import waheckspark from "../images/portfolioimgs/waheckgame.png";
import weather from "../images/portfolioimgs/weather.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaCheckCircle } from "react-icons/fa";
import "../styles/Projectsection.css";
import bankist from "../images/portfolioimgs/banklist.png";

const MobileSlider = () => {
  const projects = [
    {
      title: "Essentique",
      description: [
        "Skincare e-commerce with React and Bootstrap",
        "Redux for state management",
        "User-friendly interface and navigation",
        "Efficient product listings and cart",
      ],
      img: essentique,
      link: "https://essentique.netlify.app/",
    },
    {
      title: "Edgedynasty",
      description: [
        "Knife e-commerce with Node.js backend",
        "Redux and Context API integration",
        "Secure payment gateway implemented",
        "Dynamic product pages with cart",
      ],
      img: edge,
      link: "https://edgedynasty.com/",
    },
    {
      title: "Velvet Palate",
      description: [
        "Restaurant site with JavaScript, Bootstrap",
        "Contact form and reservation system",
        "User-friendly interface design",
        "Enhanced navigation for menu browsing",
      ],
      img: restaurant,
      link: "https://velvetpalate.netlify.app/",
    },

    {
      title: "Whack a Toad",
      description: [
        "Interactive game with leaderboard",
        "Bootstrap and local storage utilized",
        "Screenshot capture for scoring",
        "Tracks player progress efficiently",
      ],
      img: waheckspark,
      link: "https://github.com/BeRespectful/Password-Manager-Python",
    },
    {
      title: "WeatherWise",
      description: [
        "Worldwide weather app with APIs",
        "JavaScript and Bootstrap for UI",
        "Detailed global weather forecasts",
        "Responsive design for all devices",
      ],
      img: weather,
      link: "https://weatherrwise.netlify.app/",
    },
    {
      title: "Bankist",
      description: [
        "JavaScript and Bootstrap for UI",
        "Responsive design for all devices",
      ],
      img: bankist,
      link: "https://bbankist.netlify.app/",
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      {" "}
      <div className="Projectsslider">
        {" "}
        <Slider {...settings}>
          {projects.map((project, index) => (
            <div
              key={index}
              className="project-card-wrapper"
              style={{ Height: "800px" }}
            >
              <div className="project-card">
                <div>
                  <div className="image-wrapper">
                    <img src={project.img} className="img-fluid card-img" />
                    <div className="gradient-overlay"></div>
                  </div>

                  <h3 className="project-card-title">{project.title}</h3>
                </div>
                <div>
                  <ul className="project-card-description">
                    {project.description.map((item, i) => (
                      <li key={i} className="project-card-item">
                        <i
                          class="fa-solid fa-bolt fa-fade fa-sm"
                          style={{ color: "rgb(75, 75, 255)" }}
                        ></i>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <a className="github-btn" href={project.link}>
                    preview
                  </a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default MobileSlider;
