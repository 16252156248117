import "../styles/style.css";
import "../styles/Navbar.css";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, useEffect } from "react";
import imgreacticon from "../images/reacticonimage.png";
import imgjavascript from "../images/javascripticon.png";
import htmlicon from "../images/htmlicon.png";
import nodeicon from "../images/nodeicon.png";
import mongodbicon from "../images/mongodbicon.png";
import boostrapicon from "../images/bootsrapicon.png";
import tailwindicon from "../images/tailwindicon.png";
import scss from "../images/sassicon.png";
import figma from "../images/figmaicon.png";
import git from "../images/giticon.png";
import css from "../images/css image.png";
import vscode from "../images/vscodeicon.png";
import "../styles/media.css";
import "../styles/starsanimation.css";
import navlogo from "../images/perfectlogo.png";
import Form from "./Form";
import { Link as ScrollLink } from "react-scroll";
import { Element } from "react-scroll";
import Fade from "react-reveal/Fade";
import "../styles/projects.css";
import Projects from "./Projects";

import About from "./About";
import englishflag from "../images/english.jpg";
import frenchflag from "../images/french.jpg";
import MatrixRainComponent from "./matrix";
const Navbar = () => {
  const [initialnavbar, finalnavbar] = useState(true);
  const [activelanguage, setlanguage] = useState(true);

  function languagehandler() {
    setlanguage(!activelanguage);
  }

  function menuhandler() {
    finalnavbar(!initialnavbar);
  }

  const [initialNavbar, setInitialNavbar] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        setInitialNavbar(false);
      } else {
        setInitialNavbar(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // mattrix code

  return (
    <Element name="home">
      <div>
        <div className="main_nav">
          <div className="container">
            <nav id="navbar" className={initialNavbar ? "" : "navbg"}>
              <div className={`navlinks ${initialnavbar ? "" : "shownavbar"}`}>
                <ScrollLink
                  onClick={menuhandler}
                  to="skill-section"
                  smooth={true}
                  duration={500}
                >
                  {activelanguage ? "About" : "Apropos"}
                </ScrollLink>

                <ScrollLink
                  onClick={menuhandler}
                  to="section-1-home"
                  smooth={true}
                  duration={500}
                >
                  {activelanguage ? "Projects" : "Projets"}
                </ScrollLink>
                <ScrollLink
                  onClick={menuhandler}
                  to="section-2-home"
                  smooth={true}
                  duration={500}
                >
                  {activelanguage ? "Services" : "Services"}
                </ScrollLink>
                <ScrollLink
                  id="contact_navbtn"
                  onClick={menuhandler}
                  to="form-section"
                  smooth={true}
                  duration={500}
                >
                  Contact
                </ScrollLink>
                <div className="languages_suggestions small_langauge">
                  <div
                    onClick={languagehandler}
                    className={`${activelanguage ? "" : "hidden_language"}`}
                  >
                    {" "}
                    FR
                    <img src={englishflag} className="hidden_flag" alt="" />
                  </div>
                  <div
                    onClick={languagehandler}
                    className={`${activelanguage ? "hidden_language" : ""}`}
                  >
                    EN <img src={frenchflag} className="hidden_flag" alt="" />
                  </div>
                </div>
              </div>
              <div className="nav-logo">
                <img
                  src={navlogo}
                  alt=""
                  onClick={() => window.location.reload()}
                />
              </div>
              <div style={{ display: "flex" }}>
                <ScrollLink
                  to="form-section"
                  style={{ cursor: "pointer" }}
                  className="navbar_btn contactnav"
                  smooth={true}
                  duration={500}
                >
                  Contact
                </ScrollLink>
                <div
                  id="large_langauge_model"
                  className="languages_suggestions"
                >
                  <div
                    onClick={languagehandler}
                    className={`${activelanguage ? "" : "hidden_language"}`}
                  >
                    FR
                    <img src={englishflag} className="hidden_flag" alt="" />
                  </div>
                  <div
                    onClick={languagehandler}
                    className={`${activelanguage ? "hidden_language" : ""}`}
                  >
                    EN <img src={frenchflag} className="hidden_flag" alt="" />
                  </div>
                </div>
              </div>

              <div className="menu-btn" onClick={menuhandler}>
                <MenuIcon style={{ fontSize: "25px" }}> </MenuIcon>
              </div>
            </nav>
          </div>
        </div>
        <Fade bottom>
          <section
            className={`section-home ${
              initialnavbar ? "" : "section-home_blur"
            }`}
          >
            <div className="container">
              <div className="section-home-main">
                <div className="home-main">
                  {activelanguage ? (
                    <h1>
                      Web Development <br className="brole" /> Empowering Your{" "}
                      <br className="brole" />
                      Digital resence
                    </h1>
                  ) : (
                    <h1>
                      Développement Web <br className="brole" /> Renforcer votre{" "}
                      <br className="brole" />
                      présence numérique
                    </h1>
                  )}

                  <div>
                    <ScrollLink
                      id="servicebtn"
                      to="section-home"
                      style={{ cursor: "pointer" }}
                      className="navbar_btn btn-header"
                      smooth={true}
                      duration={500}
                    >
                      {activelanguage ? "See Services" : "Voir les services"}
                    </ScrollLink>
                    <ScrollLink
                      to="form-section"
                      id="contactheader"
                      style={{ cursor: "pointer" }}
                      className="navbar_btn btn-header"
                      smooth={true}
                      duration={500}
                    >
                      Contact
                    </ScrollLink>
                  </div>

                  <div className="section-home-btn "></div>
                </div>
              </div>
            </div>
          </section>
        </Fade>
        <Fade bottom>
          <section
            className={`skill-section ${
              initialnavbar ? "" : "section-home_blur"
            } `}
          >
            <div className="container">
              <div className="skillsection-content">
                <button className="servicebtn">
                  {activelanguage ? "About" : "à propos"}
                </button>
                {activelanguage ? (
                  <p className="para about_me">
                    I'm Hussein, a passionate Full Stack developer based in
                    Canada. I've contributed significantly to various projects
                    covering Website Development, Design, End-to-End Testing,
                    E-commerce Stores, RESTful API Integration, and Responsive
                    Websites. My expertise lies in crafting user-friendly web
                    applications using the MERN stack, ensuring optimal
                    performance across diverse platforms.
                  </p>
                ) : (
                  <p className="para about_me">
                    Je suis Hussein, un développeur Full Stack passionné basé au
                    Canada. J'ai contribué de manière significative à divers
                    projets couvrant le développement de Website, Design,
                    End-to-End Testing, E-commerce Stores, RESTful API
                    Integration, et Responsive Websites. Mon expertise réside
                    dans la création d'applications web conviviales en utilisant
                    la pile MERN, garantissant des performances optimales sur
                    des plateformes diverses.
                  </p>
                )}
              </div>
              <div className="container skills-main">
                <img className="skills-icon" src={htmlicon} alt="" srcset="" />
                <img className="skills-icon" src={css} alt="" srcset="" />
                <img
                  className="skills-icon"
                  src={imgjavascript}
                  alt=""
                  srcset=""
                />
                <img
                  className="skills-icon"
                  src={imgreacticon}
                  alt=""
                  srcset=""
                />
                <img className="skills-icon" src={nodeicon} alt="" srcset="" />
                <img
                  className="skills-icon"
                  src={mongodbicon}
                  alt=""
                  srcset=""
                />
              </div>
              <div className="container skills-main second-skills">
                <img
                  className="skills-icon"
                  src={boostrapicon}
                  alt=""
                  srcset=""
                />
                <img
                  className="skills-icon"
                  src={tailwindicon}
                  alt=""
                  srcset=""
                />
                <img className="skills-icon" src={scss} alt="" srcset="" />
                <img className="skills-icon" src={figma} alt="" srcset="" />
                <img className="skills-icon" src={git} alt="" srcset="" />
                <img className="skills-icon" src={vscode} alt="" srcset="" />
              </div>
            </div>
          </section>
        </Fade>
        <Fade bottom>
          <About
            activelanguage={activelanguage}
            initialnavbar={initialnavbar}
          ></About>
        </Fade>

        <Fade bottom>
          <Projects activelanguage={activelanguage} />
          {/* <section
            className={`section-2-home ${
              initialnavbar ? "" : "section-home_blur"
            } `}
          >
            <div className="container">
              <div className="container">
                <div className="section_projects">
                  <div className="projectmain_heading">
                    <button className="servicebtn">Projects</button>
                    <h1 className="heading projects_heading">
                      {activelanguage ? "Recent Work" : "Travail récent"}
                    </h1>
                  </div>

                  <div className="projects_grid">
                    <div className="projects_col">
                      <div className="project_img1 projectimg">
                        <a href="https://essentique.netlify.app/"></a>
                      </div>
                      <div className="project_description">
                        <h1 className="project_heading">Essentique</h1>
                        <p className="para">
                          {activelanguage
                            ? " E-commerce store built with React and JavaScript including user login, add-to-cart features, and securepayment connection. API connectivity"
                            : "Boutique e-commerce construite avec React et JavaScript, y compris la connexion de l'utilisateur, les fonctionnalités d'ajout au panier et la sécurité connexion de paiement. Connectivité API"}
                        </p>
                        <a href="https://essentique.netlify.app/">
                          <button>
                            <span class="shadow"></span>
                            <span class="edge"></span>
                            <span class="front text">
                              {activelanguage ? "View" : "Voir"}
                            </span>
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="projects_col">
                      <div className="project_img2">
                        {" "}
                        <a href="https://whackatoad.netlify.app/"></a>
                      </div>
                      <div className="project_description">
                        <h1 className="project_heading">Whackatoad</h1>
                        {activelanguage ? (
                          <p className="para">
                            Ensured engaging gameplay and seamless integration
                            with social platforms for enhanced user interaction.
                            Using HTML, CSS, JavaScript, Bootstrap, and
                            local storage.
                          </p>
                        ) : (
                          <p className="para">
                            Un gameplay engageant garanti et une intégration
                            transparente avec les plateformes sociales pour une
                            interaction utilisateur améliorée. Utilisation de
                            HTML, CSS, JavaScript, Bootstrap et stockage local.
                          </p>
                        )}
                        <a href="https://whackatoad.netlify.app/">
                          <button>
                            <span class="shadow"></span>
                            <span class="edge"></span>
                            <span class="front text">
                              {" "}
                              {activelanguage ? "View" : "Voir"}
                            </span>
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="projects_col">
                      <div className="project_img3">
                        {" "}
                        <a href="https://velvetpalate.netlify.app/"></a>
                      </div>
                      <div className="project_description">
                        <h1 className="project_heading">Restaurant</h1>
                        {activelanguage ? (
                          <p className="para">
                            Created a restaurant reservation website with HTML,
                            CSS, JavaScript, and EmailJS as well as email
                            notifications for reservation confirmations.
                          </p>
                        ) : (
                          <p className="para">
                            Création d'un site de réservation de restaurant en
                            HTML, CSS, JavaScript et EmailJS ainsi que le
                            courrier électronique notifications pour les
                            confirmations de réservation.
                          </p>
                        )}
                        <a href="https://velvetpalate.netlify.app/">
                          <button>
                            <span class="shadow"></span>
                            <span class="edge"></span>
                            <span class="front text">
                              {" "}
                              {activelanguage ? "View" : "Voir"}
                            </span>
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="projects_col">
                      <div className="project_img4">
                        {" "}
                        <a href="https://weatherrwise.netlify.app/"></a>
                      </div>
                      <div className="project_description">
                        <h1 className="project_heading">Tourists</h1>
                        {activelanguage ? (
                          <p className="para">
                            Weather forecasts, offering insights for the
                            upcoming days. Built using JavaScript to fetch and
                            display weather data from an API.
                          </p>
                        ) : (
                          <p className="para">
                            Prévisions météorologiques, offrant des aperçus pour
                            le prochain jours. Construit en utilisant JavaScript
                            pour récupérer et afficher données météorologiques à
                            partir d'une API.
                          </p>
                        )}
                        <a href="https://weatherrwise.netlify.app/">
                          <button>
                            <span class="shadow"></span>
                            <span class="edge"></span>
                            <span class="front text">
                              {" "}
                              {activelanguage ? "View" : "Voir"}
                            </span>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </Fade>
        <Fade bottom>
          <MatrixRainComponent activelanguage={activelanguage} />
        </Fade>

        <Fade bottom>
          <div id="form-section">
            <Form
              activelanguage={activelanguage}
              initialnavbar={initialnavbar}
            ></Form>
          </div>
        </Fade>
      </div>
    </Element>
  );
};

export default Navbar;
